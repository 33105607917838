<template lang="pug">
  div
    slot(name="activator")
      v-btn(small color='primary'
        :disabled="disabled"
        @click="show"
        outlined
      ) {{ cancelStatus }}

    base-dialog(
      v-model="showing"
      width="500px"
      :title="`Set '${cancelStatus}' status`"
    )
      template(#dialog-body)
        .body-content
          p Are you sure you want to set candidate '{{ cancelStatus }}' status?
          app-textarea(
            :value="comment"
            @change="comment = $event"
            placeholder="Required comment"
            required
          )
      template(#dialog-footer)
        app-button(color="#f4f7f9" @click.native="hide" hide-shadow).mr-3
          span.text-label Close
        app-button(color="primary" @click.native="send") Ok
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
import BaseDialog from '@/components/global/actions/BaseDialog'

export default {
  inject: ['svc'],

  mixins: [errorsMixin],

  props: {
    ID: Number,
    canceled: Boolean,
    disabled: Boolean,
  },

  data: () => ({
    showing: false,
    loading: false,
    comment: ''
  }),

  computed: {
    cancelStatus() {
      if (!this.canceled) return 'cancel'
      else return 'uncancel'
    }
  },

  methods: {
    show() {
      this.showing = true
    },

    hide() {
      this.showing = false
    },

    async send() {
      if (/^\s*$/.test(this.comment)) return this.$notify({text: 'Field comment cant be empty', type: 'error'})

      if (!this.loading) {
        this.loading = true
        try {
          let res = await this.svc().addCustomLog(this.ID, this.comment)
          this.$emit('send', res)
          await this.svc().setCancel(this.ID)
          if (this.canceled) this.$emit('reservedExam:update')
          this.hide()
          this.clear()
        } catch (err) {
          this.processError(err)
        } finally {
          this.loading = false
        }
      }
    },

    clear() {
      this.comment = ''
    }
  },

  watch: {
    showing(value) {
      if (!value) this.clear()
    }
  },

  components: {
    BaseDialog,
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    appTextarea: () => import('@/components/global/actions/BaseTextarea.vue')
  }
}
</script>


<style lang="scss">
.body-content {
  padding: 15px;
}
</style>
